$(document).ready(function() {
    if(!$('.navbar-hamburger').is(':visible')) {
        $('.navbar-menu ul li.children>a').click(function(e) {
            e.preventDefault();
        });
        $('.navbar-menu ul li.children').hover(function() {
            $(this).children('.collapse').stop().slideToggle();
        });
    } else {
        $('.navbar-menu ul li.children>a').click(function(e) {
            e.preventDefault();
            $(this).parent().toggleClass('open').children('.collapse').stop().slideToggle();
            
        });
    }
    

    $('.navbar-hamburger a').click(function(e) {
        e.preventDefault();
        $('.navbar-menu').toggleClass('show');
    });
    $('.navbar-lang li.current a').on('click',function(e){
        e.preventDefault();
        $('.navbar-lang').toggleClass('open');
    });

    //FOOTER
    // $('footer .footer-menu ul li.children>a').click(function(e) {
    //     e.preventDefault();
    //     $(this).toggleClass('open');
    //     $(this).parent().toggleClass('open').children('.collapse').stop().slideToggle();
        
    // });
});
require('./js/jquery-global');
require('./js/jquery-cookie');
require('./css/jquery.fancybox.css');
require('./css/purecookie.css');
require('./sass/styles.scss');
require('owl.carousel');
require('bootstrap');

require('./js/jquery.fancybox.js');
require('./js/layout/slider.js');
require('./js/layout/menu.js');
require('./js/pages/our-activity.js');
require('./js/layout/products.js');
require('./js/layout/splash.js');
